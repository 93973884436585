import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Img from "gatsby-image"
import SEO from "../../components/seo"

const PrivateFarmTours = () => {
  const data = useStaticQuery(graphql`
    query {
      childrenWalking: file(relativePath: { eq: "visit-us/private-farm-tours/children-walking-to-barn.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      peopleOnTrail: file(relativePath: { eq: "visit-us/private-farm-tours/people-on-trail.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      womanPettingDonkey: file(relativePath: { eq: "visit-us/private-farm-tours/woman-petting-donkey.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
    }
  `)
  return (
    <Layout>
      <SEO title="Private Farm Tours" />
      <br />
      <h1>Private Farm Tours</h1>
      <div>
        <p>
          <strong>$8 Per Person ($24 Minimum)</strong>
        </p>
        <p>We are a down to earth working farm offering tours to small groups.</p>
        <p>The Baxter Barn in Fall City sits on 2½ acres with trees, gardens, pastures, lawns, and a seasonal creek. It is a working farm with horses, mini-donkeys, chickens, pheasants, and quail; quiet and peaceful, and is nestled at the end of a rural road with plenty of parking.</p>
        <p>Limited Parking up to 3 cars/vans</p>
        <p>Give us a call or <a href="mailto:baxterbarn@hotmail.com" target="_blank" rel="noopener noreferrer">email us</a>!</p>
        <div class="row mt-5">
          <div class="col text-right"><Img alt="Children walking towards Baxter Barn" fixed={data.childrenWalking.childImageSharp.fixed} /></div>
          <div class="col text-center"><Img alt="People on trail at Baxter Barn" fixed={data.peopleOnTrail.childImageSharp.fixed} /></div>
          <div class="col text-left"><Img alt="Woman petting donkey at Baxter Barn" fixed={data.womanPettingDonkey.childImageSharp.fixed} /></div>
        </div>
      </div>
  </Layout>
)}

export default PrivateFarmTours
